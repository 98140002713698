import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/SkittlesGummiesComponents/components/layout"
import SEO from "../../components/SkittlesGummiesComponents/components/seo"
//import Agegate from "../components/agegate"
import App from "../../components/SkittlesGummiesComponents/components/app"

const IndexPage = () => {
  // const [oneTrust, setOneTrust] = useState(false)
  // const [ageGateShow, setAgeGateShow] = useState(true)
  const [app, setApp] = useState(false)

  let ageCookie = new Cookies()
  useEffect(() => {
    if (ageCookie.get("isOldEnough") === "yes") {
      setApp(true)
    } else {
      ageCookie.set("isOldEnough", "no", { path: "/" })
      // setAgeGateShow(true)
      setApp(false)
      navigate("/SkittlesGummies/agegate/")
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../../SkittlesGummies/css/app.css" />
        <link
          rel="stylesheet"
          href="../../SkittlesGummies/css/responsive.css"
        />
        <link
          rel="stylesheet"
          href="../../SkittlesGummies/css/animation.css"
        />
      </Helmet>
      <Layout pageInfo={{ pageName: "Home" }}>
        <SEO title="Skittles Gummi Sanctuary" />
        {app && <App />}
      </Layout>
    </>
  )
}

export default IndexPage
